
.country-input {
    height: 50px;
    width: 100%;
    border-radius: 5px;
    padding: 15px 24px 15px 95px;
    color: #747373;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #CBC9C9;
    background-color: #ffffff;
    text-align: left;
    z-index: 0;
  }

  .country-input:hover, .country-input:focus {
   border: 1px solid var(--ion-color-primary);
    outline: none;
  }

  .country-phone-code {
    width: 100%;
    position: relative;
    display: inline-block;
    z-index: 0;
  }

   .country-phone-input-flag-code {
      position: absolute;
      top: 0;
      height: 100%;
      width: 80px;
      display: flex;
      align-items: center;
      left: 24px;
      z-index: 0;
      cursor: pointer;
  }

   .country-phone-input-flag-code p {
      position: relative;
      top: 2px;
      font-size: 14px;
      margin-right: 6px;
  }

   .country-phone-input-flag-code img.flag {
    width: 17px;
    height: 14px;
    margin-right: 6px;
  }

   .country-phone-input-flag-code img.caret {
      padding-top: 2px;
      margin-left: 5px;
  }

  .country-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    z-index: 999;
    border-bottom: 23px solid var(--ion-color-tertiary);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    z-index: 99999;
  }

  .country-content ion-label {
    cursor: pointer;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 39px;
    color: #8E8E8E;
  }

  .country-content ion-label.item {
      --border-style: none !important;
  }

  .country-content ion-radio {
    margin-right: 10px;
  }

  .country-content a:hover {background-color: #ddd}

  .show {display:block;}