body {
	margin: 0;
	font-family: "Archivo";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-family: "Archivo", serif !important;
}

/* Tailwind Overrides */

.bg-red {
	background-color: #e10e19 !important;
}

.bg-red-500 {
	background-color: #e10e19 !important;
}

.hover\:bg-red:hover {
	background-color: #e10e19 !important;
}

.text-red {
	color: #e10e19 !important;
}

.border-red {
	border-color: #e10e19 !important;
}

.fill-red path {
	fill: #e10e19 !important;
}

.path-red circle {
	fill: #e10e19 !important;
}

.from-red-500 {
	--tw-gradient-from: #e10e19 !important;
	--tw-gradient-stops: var(--tw-gradient-from),
		var(--tw-gradient-to, rgba(255, 152, 0, 0));
}

.to-red-700 {
	--tw-gradient-to: #e10e19 !important;
}

.shadow-lg-red {
	--tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
		0 10px 10px -5px rgba(0, 0, 0, 0.04),
		0 13px 24px -11px rgba(0, 0, 0, 0.04) !important;
}

.font-serif,
.font-sans {
	font-family: "Archivo", serif !important;
}

.hover\:text-white:hover {
	--tw-text-opacity: 1;
	color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.text-white {
	color: #ffffff !important;
}

/* color defs */

.text-success {
	color: rgb(76, 175, 80) !important;
}

.bg-success {
	background: rgba(76, 175, 80, 0.8) !important;
}

.border-success {
	background: rgb(76, 175, 80, 0.5) !important;
}

@media (min-width: 768px) {
	.md\:h-56 {
		height: 14rem
			/* 224px */
			!important;
	}

	.md\:grid-cols-7-max {
		grid-template-columns: repeat(7, minmax(0, max-content)) !important;
	}
}

label>span {
	flex-shrink: 0 !important;
}

.dropdown>span {
	margin-left: auto;
	justify-self: flex-end;
}

div[data-tippy-root] span:hover {
	/* background: #e10e19 !important; */
	cursor: pointer;
}

.tippy-box {
	max-width: 100% !important;
}

.sigCanvas {
	position: static;
	border: 1px solid lightgray;
	border-radius: 0.75rem;
	background-color: white;
}

.bg-image {
	background-image: url("assets/img/bg-login-image-black.png");
	/* The image used */
	background-color: #cccccc;
	/* Used if the image is unavailable */
	height: 100vh;
	/* You must set a specified height */
	background-position: center;
	/* Center the image */
	background-repeat: no-repeat;
	/* Do not repeat the image */
	background-size: cover;
	/* Resize the background image to cover the entire container */
}

.modal-width-lg {
	width: 100vw;
}

.iframe-content-lg {
	width: 100vw;
	height: 125vh;
}

@media (min-width: 768px) {
	.modal-width-lg {
		width: 70vw;
	}

	.iframe-content-lg {
		width: 70vw;
		height: 125vh;
	}
}

@media (min-width: 1441px) {
	.modal-width-lg {
		width: 40vw;
	}

	.iframe-content-lg {
		width: 40vw;
		height: 80vh;
	}
}

.msg.user::after {
	content: "▼";
	color: #e10e19;
	display: inline-block;
	position: absolute;
	top: 0;
	right: -13px;
	transform: rotate(-90deg);
}

.msg.admin::after {
	content: "▼";
	color: #9e9e9e;
	display: inline-block;
	position: absolute;
	top: 0;
	left: -13px;
	transform: rotate(90deg);
}

.chat div[data-tippy-root] {
	bottom: 1em !important;
}

.shadow-from-bottom {
	box-shadow: rgba(0, 0, 0, 0.1) 0px -50px 36px -28px inset,
		rgba(0, 0, 0, 0.1) 0px 50px 36px -28px inset !important;
}

/* .shadow-from-top {
  box-shadow: ;
} */

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	background-color: #f5f5f5;
}

::-webkit-scrollbar {
	width: 10px;
	background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #c1c1c1;
}

.xs-max-w-80vw {
	max-width: 25vw;
}

@media (max-width: 768px) {
	.xs-max-w-80vw {
		max-width: 70vw;
	}

	.react-pdf__Page canvas {
        width: 100%; /* Ensures the canvas width fits the container */
        height: auto; /* Adjusts height automatically to maintain aspect ratio */
        max-width: 100%; /* Prevents the canvas from exceeding the container width */
        display: block; /* Removes any extra space around the canvas */
    }

	.mobile-z-max {
		z-index: 999;
	}
}

/* .tippy-content > div > div {
  max-height: 30rem;
  overflow: auto;
} */

.react-pdf__Page__annotations.annotationLayer {
	display: none !important;
}

/* FILE UPLOADS EXAMPLE */

.dropzone.active {
	border-color: #fff;
}

.file {
	text-decoration: none;
	color: rgba(255, 255, 255, 0.8);
	display: block;
	background-color: #41415d;
	border-radius: 10px;
	margin-top: 20px;
	padding: 10px;
	position: relative;
	overflow: hidden;
}

.file .progress {
	background-color: rgba(80, 80, 120, 0.8);
	position: absolute;
	inset: 0;
	width: 0;
	padding-top: 10px;
	text-align: center;
}

.file .progress.done {
	top: 100px;
}

/* focus */

*:focus {
	/* outline: none !important;
  border: none;
  box-shadow: 0 0 10px #719ECE; */
	/* outline: none !important; */
	/* border:1px solid rgba(76,175,80, 0.8); */
	border-radius: 0.75rem
		/* 12px */
	;
}

.bg-red-250 {
	background-color: #ecb2b2;
}

button.ddup+div[data-tippy-root] {
	top: -1.5rem !important;
}

.tooltip:hover:after {
	content: attr(data-tooltip);
	position: absolute;
	z-index: 100;
	top: 100%;
	left: 50%;
	margin-left: -50px;
	padding: 10px;
	width: 100px;
	border-radius: 8px;
	background: #ea0202;
	color: #6c6c6c;
	text-align: center;
	font-size: 14px;
	line-height: 1.5;
}

.tool {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.paper-options {
	display: flex;
	align-items: center;
}

.paper-icon {
	width: 15px;
	height: 15px;
	margin-right: 5px;
}

/* Default styles */
.conditional-height {
	height: 20rem;
}

.default-height {
	height: 16rem;
	/* I assume this was meant to be 'px' not just '50' */
}

/* Medium devices (e.g., tablets, around 768px) */
@media (min-width: 768px) {
	.conditional-height {
		height: 12rem;
	}

	.default-height {
		height: 8rem;
	}
}

/* Large devices (e.g., desktops, 1024px and up) */
@media (min-width: 1024px) {
	.conditional-height {
		height: 11rem;
	}
}

@media (max-width: 768px) {
	.floating-royalties {
		width: 96%;
	}
}

@media (min-width:330px) and (max-width: 500px) {
	.floating-royalties {
		width: 94%;
	}
}

@media (min-width:300px) and (max-width: 330px) {
	.floating-royalties {
		width: 92%;
	}
}

@media (max-width: 300px) {
	.floating-royalties {
		width: 90%;
	}
}