p {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.428571429;
    letter-spacing: unset;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

a {
    text-decoration: none;
    color: rgba(0, 0, 0);
    font-weight: 400;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.428571429;
    letter-spacing: unset;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.right {
    float: right;
}

.left {
    float: left;
}

.grid {
    display: grid;
}

.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.flex-align-center {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-justify-center {
    display: flex;
    justify-content: center;
}

.flex-justify-left {
    display: flex;
    justify-content: flex-start;
}

.flex-justify-right {
    display: flex;
    justify-content: flex-end;
}


.flex-wrapper {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex-end;
}

.flex-left-wrapper {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: left;
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.bold {
    font-weight: 700;
    ;
}

.width-full {
    width: 100%;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.margin-none {
    margin: 0 !important;
}

.padding-none {
    padding: 0;
}

.rotate180 {
    transform: rotate(180deg);
}

.rotateRight90 {
    transform: rotate(-90deg);
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999999;
}

.line-30 {
    line-height: 30px;
}

.underline {
    text-decoration: underline;
}

.shadow {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
}

.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.dark {
    color: #000000;
}

.round {
    border-radius: 50%;
}

.text-grey {
    color: #999999 !important;
}

.relative {
    position: relative;
}

.font-weight-400 {
    font-weight: 400;
}

.uppercase {
    text-transform: uppercase;
}

.mb-20 {
    margin-bottom: 20px;
}

/* no hover effect on ionic buttons */
.no-hover {
    --background-hover: var(--background);
}

.cursor-pointer {
    cursor: pointer !important;
}

/* GS Grid System */
.gs-container-xl,
.gs-container-md {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.gs-container-form {
    padding-left: 5%;
    padding-right: 5%;
}

.m-auto {
    margin: auto;
}

@media (min-width: 1200px) {
    .gs-container-xl {
        max-width: 1140px;
    }

    .gs-container-md {
        max-width: 960px;
    }
}

.gs-container-sm {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

@media (min-width: 576px) {
    .gs-container-sm {
        max-width: 540px;
    }
}

.gs-container-full {
    width: 100%;
}

.gs-12 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1rem;
}

.gs-10 {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 0.6em;
}

.gs-13 {
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    grid-gap: 0.6em;
}

.gs-gap-small {
    grid-gap: 0.5rem;
}

.gs-gap-2 {
    grid-gap: 2rem;
}

.gs-gap-3 {
    grid-gap: 3rem;
}

.gs-gap-4 {
    grid-gap: 4rem;
}

.gs-gap-5 {
    grid-gap: 5rem;
}

.gs-12-full {
    grid-column: 1 / -1;
}

.gs-12-left.gs-12-right {
    grid-column: 1 / -1;
}

@media (min-width: 800px) {
    .gs-12-left {
        grid-column: 1 / span 6;
    }

    .gs-12-right {
        grid-column: 7 / span 6;
    }
}

.gs-13-1-5,
.gs-13-2-5,
.gs-13-3-5,
.gs-13-4-5,
.gs-13-5-5,
.gs-13-1-3,
.gs-13-2-3,
.gs-13-3-3 {
    grid-column: 1 / -1;
}

@media (min-width: 800px) {
    .gs-13-1-5 {
        grid-column: 1 / span 3;
    }

    .gs-13-2-5 {
        grid-column: 4 / span 3;
    }

    .gs-13-3-5 {
        grid-column: 7 / span 3;
    }

    .gs-13-4-5 {
        grid-column: 10 / span 3;
    }

    .gs-13-5-5 {
        grid-column: 13 / span 1;
    }


    .gs-13-1-3 {
        grid-column: 1 / span 6;
    }

    .gs-13-2-3 {
        grid-column: 7 / span 6;
    }

    .gs-13-3-3 {
        grid-column: 13 / span 1;
    }
}

.gs-1-4,
.gs-2-4,
.gs-3-4,
.gs-4-4,
.gs-12-4 {
    grid-column: 1 / -1;
}

.gs-1-2,
.gs-2-2 {
    grid-column: 1 / -1;
}

@media (min-width: 800px) {

    .gs-1-4,
    .gs-3-4 {
        grid-column: 1 / span 6;
    }

    .gs-2-4,
    .gs-4-4 {
        grid-column: 7 / span 6;
    }

    .gs-12-4 {
        grid-column: 1 / span 6;
    }
}

@media (min-width: 1200px) {
    .gs-1-4 {
        grid-column: 1 / span 3;
    }

    .gs-2-4 {
        grid-column: 4 / span 3;
    }

    .gs-3-4 {
        grid-column: 7 / span 3;
    }

    .gs-4-4 {
        grid-column: 10 / span 3;
    }

    .gs-12-4 {
        grid-column: 1 / span 6;
    }

    .gs-2-8 {
        grid-column: 5 / span 8;
    }

    .gs-1-2 {
        grid-column: 1 / span 6;
    }

    .gs-2-2 {
        grid-column: 7 / span 6;
    }
}

.gs-1-2.xs {
    grid-column: 1 / span 6;
}

.gs-2-2.xs {
    grid-column: 7 / span 6;
}

.half .gs-1-4,
.fixed .gs-1-4 {
    grid-column: 1 / span 3;
}

.half .gs-2-4,
.fixed .gs-2-4 {
    grid-column: 4 / span 3;
}

.half .gs-3-4,
.fixed .gs-3-4 {
    grid-column: 7 / span 3;
}

.half .gs-4-4,
.fixed .gs-4-4 {
    grid-column: 10 / span 3;
}

@media (max-width: 800px) {

    .half .gs-1-4,
    .half .gs-3-4 {
        grid-column: 1 / span 6;
    }

    .half .gs-2-4,
    .half .gs-4-4 {
        grid-column: 7 / span 6;
    }

    .gs-2-8 {
        grid-column: 1 / -1;
    }
}

.gs-1-3,
.gs-2-3,
.gs-3-3,
.gs-12-3,
.gs-23-3 {
    grid-column: 1 / -1;
}

@media (min-width: 1200px) {
    .gs-1-3 {
        grid-column: 1 / span 4;
    }

    .gs-2-3 {
        grid-column: 5 / span 4;
    }

    .gs-3-3 {
        grid-column: 9 / span 4;
    }

    .gs-12-3 {
        grid-column: 1 / span 8;
    }

    .gs-23-3 {
        grid-column: 5 / span 8;
    }
}

.gs-1-5,
.gs-2-5,
.gs-3-5,
.gs-4-5,
.gs-5-5 {
    grid-column: 1 / -1;
}

@media (min-width: 800px) {

    .gs-1-5,
    .gs-3-5 {
        grid-column: 1 / span 5;
    }

    .gs-2-5,
    .gs-4-5 {
        grid-column: 6 / span 5;
    }

    .gs-5-5 {
        grid-column: 3 / span 6;
    }
}

@media (min-width: 1200px) {
    .gs-1-5 {
        grid-column: 1 / span 2;
    }

    .gs-2-5 {
        grid-column: 3 / span 2;
    }

    .gs-3-5 {
        grid-column: 5 / span 2;
    }

    .gs-4-5 {
        grid-column: 7 / span 2;
    }

    .gs-5-5 {
        grid-column: 9 / span 2;
    }

}

@media (min-width: 800px) {

    .gs-1-6,
    .gs-3-6,
    .gs-5-6 {
        grid-column: 1 / span 6;
    }

    .gs-2-6,
    .gs-4-6,
    .gs-6-6 {
        grid-column: 6 / span 6;
    }
}

@media (min-width: 1200px) {
    .gs-1-6 {
        grid-column: 1 / span 2;
    }

    .gs-2-6 {
        grid-column: 3 / span 2;
    }

    .gs-3-6 {
        grid-column: 5 / span 2;
    }

    .gs-4-6 {
        grid-column: 7 / span 2;
    }

    .gs-5-6 {
        grid-column: 9 / span 2;
    }

    .gs-6-6 {
        grid-column: 11 / span 2;
    }

}


.h-200 {
    height: 200px;
}

.h-600 {
    height: 600px;
}

.h-500 {
    height: 500px;
}

.h-100 {
    height: 100px;
}

.h-80 {
    height: 80px;
}

.h-50 {
    height: 50px;
}

.h-30 {
    height: 30px;
}

.h-20 {
    height: 20px;
}

.h-10 {
    height: 10px;
}

.h-0 {
    height: 0;
}

.h-90vh {
    height: 90vh;
}

.w-100 {
    width: 100%;
}

.w-auto {
    width: auto;
}

.h-full {
    height: 100%;
}

.dev-red {
    background-color: red;
}

.dev-green {
    background-color: green;
}

.underline {
    text-decoration: underline !important;
}

.gs-flex,
.gs-flex-rev {
    display: flex;
    flex-direction: row;
}

.ui.container iframe {
    max-width: 80vw;
}

.text-white {
    color: #d7d7d7 !important;
}

.text-black {
    color: #121212 !important;
}

.hover\:text-black:hover {
    color: #121212 !important;
}

.bg-primary {
    background-color: #121212 !important;
}

.bg-change {
    background-color: #ffffff !important;
    color: black !important;
}

.bg-change svg path {
    fill: black !important;
}

.bg-white {
    background: white;
}

.bg-grey {
    background: #c3c3c3ce;
}

.dropdown.bg-white {
    background: white !important;
}

.fill-white,
.fill-white path {
    fill: white;
}

@media (max-width: 800px) {
    .gs-flex {
        flex-direction: column;
    }

    .gs-flex-rev {
        flex-direction: column-reverse;
    }
}

@media (max-width: 576px) {
    .hide-sm {
        display: none !important;
    }
}

@media (max-width: 768px) {
    .hide-md {
        display: none !important;
    }
}

@media (max-width: 992px) {
    .hide-lg {
        display: none !important;
    }
}

@media (max-width: 1200px) {
    .hide-xl {
        display: none !important;
    }
}

@media (max-width: 1400px) {
    .hide-xxl {
        display: none !important;
    }
}

.pos-rel {
    position: relative;
}

.pos-abs {
    position: absolute !important;
}

@media (min-width: 800px) {
    .body-desktop-pad {
        padding: 0 5em;
    }
}

.br-10 {
    border-radius: 10px;
}

.mt-0 {
    margin-top: 0 !important;
}

.color-primary {
    color: var(--color-primary-primary);
}

.text-white {
    color: #ffffff;
}

.text-black {
    color: #000000 !important;
}

button.text-black {
    color: #000000 !important;
}

.ml-1 {
    margin-left: 1rem;
}

.mr-1 {
    margin-right: 1rem;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.p-1 {
    padding: 1em;
}

.p-36 {
    padding: 36px;
}


@media (max-width: 768px) {
    h2 {
        font-size: 20px;
    }
}

.icon-16 {
    height: 16px;
    width: auto;
}

.top-0 {
    top: 0;
}

.r-2 {
    right: 2em;
}

.r-4 {
    right: 4em;
}

.w-50 {
    width: 50%;
}

/* Font Weight */

.fw-100 {
    font-weight: 100;
}

.fw-200 {
    font-weight: 200;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}

.hover-o-30:hover {
    background-color: #00000030;
}


@media screen and (max-width: 768px) {
    .hide-sm {
        display: none;
    }
}

.overflow-visible {
    overflow: visible !important;
}