.pac-container:after {
    display: none;
}

.pac-item {
    padding: 12px 16px;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    cursor: pointer;
    display: block;
}

.pac-item span.pac-icon.pac-icon-marker {
    position: relative;
    bottom: 7px;
}

.pac-item span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    font-family: "Archivo";
}